"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
// internal
// import { add_cart_product } from "@/redux/features/cart";
// import { add_to_compare } from "@/redux/features/compare";
import { handleModalProduct, handleOpenModal } from "@/redux/features/utility";
// import { add_to_wishlist } from "@/redux/features/wishlist";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
// import { IProduct } from "@/types/product-d-t";

// img style
const imgStyle: any = {
  width: "100%",
  height: "100%",
  background: "#f6f6f6",
  objectFit: "cover",
  backgroundSize: "cover",
  // padding: "10px",
};

const ProductItem = ({
  product,
  setLoading,
  isSearchPopup,
}: {
  product: any;
  setLoading?: any;
  isSearchPopup?: any;
}) => {
  const [isItemAddToCart, setIsItemAddToCart] = useState(false);
  // const [isCompareAdd, setIsCompareAdd] = useState(false);
  const [isWishlistAdd, setIsWishlistAdd] = useState(false);
  const { cart_products } = useAppSelector((state) => state.cart);
  const { wishlist } = useAppSelector((state) => state.wishlist);
  const { compare_products } = useAppSelector((state) => state.compare);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsItemAddToCart(cart_products.some((i) => i.id === product.id));
    setIsWishlistAdd(wishlist.some((i) => i.id === product.id));
    // setIsCompareAdd(compare_products.some((i) => i.id === product.id));
  }, [cart_products, compare_products, product.id, wishlist]);

  const handleProductModal = (prd: any) => {
    dispatch(handleModalProduct({ product: prd }));
    dispatch(handleOpenModal());
  };

  // const [wishlistProduct, setWishlistProduct] = useState<any[]>(() => {
  //   // Initialize state from localStorage
  //   return JSON.parse(localStorage.getItem("wishlist") || "[]");
  // });

  const addToWishlist = (prod: any) => {
    let wishlistProduct = JSON.parse(localStorage.getItem("wishlist") || "[]");

    const productExists = wishlistProduct.some(
      (item: any) => item.id === prod.id
    );

    console.log(productExists);

    if (productExists) {
      wishlistProduct = wishlistProduct.filter(
        (item: any) => item.id !== prod.id
      );
    } else {
      wishlistProduct.push({ ...prod, count: 1 });
    }

    localStorage.setItem("wishlist", JSON.stringify(wishlistProduct));

    // setWishlistProduct((prevWishlist: any[]) => {
    //   const productExists = prevWishlist.some((item) => item.id === prod.id);

    //   if (productExists) {
    //     // Remove product
    //     const updatedWishlist = prevWishlist.filter(
    //       (item) => item.id !== prod.id
    //     );
    //     localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
    //     return updatedWishlist;
    //   } else {
    //     // Add product
    //     const updatedWishlist = [...prevWishlist, { ...prod, count: 1 }];
    //     localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
    //     return updatedWishlist;
    //   }
    // });
  };

  const handleClick = async () => {
    setLoading(true);
  };

  return (
    <>
      <div
        className={`product__wrapper mb-60 ${
          isSearchPopup && "product__wrapper__searchbar"
        }`}
      >
        <div
          className={`product__thumb ${
            isSearchPopup && "product__thumb__searchbar"
          }`}
        >
          <Link
            href={`/product-details/${product.slug}`}
            onClick={() => handleClick()}
          >
            <Image
              src={
                product?.images
                  ? product?.images[0]?.image ?? "/noimage.png"
                  : "/noimage.png"
              }
              alt="product-img"
              width={255}
              height={325}
              style={imgStyle}
            />
            {product && product?.images && product?.images[1]?.image && (
              <Image
                className="product__thumb-2"
                src={
                  product?.images
                    ? product?.images[1]?.image ?? "/noimage.png"
                    : "/noimage.png"
                }
                alt="product-img"
                width={255}
                height={325}
                style={imgStyle}
              />
            )}
          </Link>
          {!isSearchPopup && (
            <div className="product__action transition-3">
              <a
                // onClick={() => dispatch(add_to_wishlist(product))}
                onClick={() => {
                  addToWishlist(product);
                  // setLoading(true);
                }}
                className={`cursor-pointer ${isWishlistAdd ? "active" : ""}`}
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Add to Wishlist"
              >
                <i className="fal fa-heart"></i>
              </a>
              {/* <a
            onClick={() => dispatch(add_to_compare(product))}
            className={`cursor-pointer ${isCompareAdd ? "active" : ""}`}
            data-toggle="tooltip"
            data-placement="top"
            title="Compare"
          >
            <i className="fal fa-sliders-h"></i>
          </a> */}
              <a
                className="cursor-pointer"
                onClick={() => handleProductModal(product)}
              >
                <i className="fal fa-search"></i>
              </a>
            </div>
          )}

          {product?.productAttributes &&
            product?.productAttributes[0]?.discountPercent > 0 && (
              <div className="product__sale">
                {product?.productAttributes && (
                  <span className="new">
                    {product?.productAttributes[0]?.discountPercent}%
                  </span>
                )}
                {product?.productAttributes && (
                  <span className="percent">
                    -{product?.productAttributes[0]?.discountPrice} TK
                  </span>
                )}
              </div>
            )}
        </div>
        <div className="product__content p-relative">
          <div className="product__content-inner">
            <h4>
              <Link
                href={`/product-details/${product?.slug}`}
                dangerouslySetInnerHTML={{ __html: product?.name }}
                onClick={() => handleClick()}
              ></Link>
            </h4>
            <div className="product__price transition-3">
              <span>
                {product.productAttributes &&
                  product?.productAttributes[0]?.discountedRetailPrice}{" "}
                TK
              </span>
              {product.productAttributes &&
                product?.productAttributes[0]?.discountPercent > 0 && (
                  <span className="old-price">
                    {product.productAttributes[0]?.retailPrice} TK
                  </span>
                )}
            </div>
          </div>
          <div className="add-cart p-absolute transition-3">
            {isItemAddToCart ? (
              <Link
                href="/cart"
                className="cursor-pointer"
                onClick={() => handleClick()}
              >
                View Cart
              </Link>
            ) : (
              <>
                {!isSearchPopup && (
                  <a
                    // onClick={() => dispatch(add_cart_product(product))}
                    onClick={() => handleProductModal(product)}
                    className="cursor-pointer"
                  >
                    + Add to Cart
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItem;
